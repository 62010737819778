<template>
  <div id="app" class="row">
      <div class="col" v-for="day in forecast.data" :key="day.time">
          <div class="card">
            <h3>{{ getDOW(day.time) }}</h3>
            <span v-html="getIcon(day.icon)"></span>
            <p>{{ day.temperatureHigh | round }}&deg;F / {{ day.temperatureLow | round }}&deg;F</p>
            <p>{{ day.summary }}</p>
            <p>{{ day.precipProbability | percent }}% chance of rain</p>
          </div>
        </div>
  </div>
</template>

<script>
import axios from 'axios'
import icons from './plugins/weather-icons'
import moment from 'moment'

export default {
  name: 'app',
  props: { geo: {type: String, default: '1.489706,124.84620' } },
  data: () => ({
      forecast: [],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  }),
  filters: {
      round: function(value) {
          return Math.round(value)
      },
      percent: function(value) {
          return Math.round(value * 100)
      }
  },
  methods: {
      getIcon: function(name) {
          return icons.icons[name]
      },
      getDOW: function(stamp) {
          // let dow = new Date(parseInt(stamp)).getDay()
          // let newDay = new Date(stamp)
          let newDay = moment.unix(stamp)
            // eslint-disable-next-line
          //console.log(newDay.format())
          let dow = newDay.days()
          return this.days[dow]
      }
  },
  created: function () {
      axios.get('https://us-central1-hotels-8fb5d.cloudfunctions.net/weather?geo=' + this.geo)
        .then(response => {
            // eslint-disable-next-line
          // console.log(response.data)
          this.forecast = response.data.daily
            // eslint-disable-next-line
          //console.log(this.forecast)
      })
  }
}
</script>

<style>
    #app { text-align: center }
    .forecast { width: 22%; padding: .5em; display: inline-block; text-align: center; vertical-align: top; }
    .inner { border: 1px solid black; padding: .5em;  }
    svg { width: 48px; height: 48px; fill: #064273; margin-right: 12px; }
    .temp { font-size: 48px; color: #064273; font-weight: 700; }
    p { margin: 0; }
    .row {
        width: 100%;
        flex-wrap: wrap;
        flex: 1 1 auto;
        display: flex;
        -webkit-box-flex: 1;
    }
    .col {
        -webkit-box-flex: 0;
        flex: 0 0 22%;
        max-width: 22%;
        padding: 12px;
    }
    .card {
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
        border-radius: 4px;
        position: relative;
        transition: box-shadow .28s cubic-bezier(.4,0,.2,1),-webkit-box-shadow .28s cubic-bezier(.4,0,.2,1);
        will-change: box-shadow;
        padding-bottom: 1.5em; padding-top: .3em
    }
</style>
